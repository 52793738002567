import React from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Button,
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useSnackbar, withSnackbar } from "notistack";
import Modal from "../shared/modal/material_dialog";
import Typography from "@material-ui/core/Typography";
import pureIcons from "../shared/pure_icons";
import TableSkeleton from "../shared/skeletons/table_skeleton";
import Backdrop from "../shared/backdrop";
import $ from "jquery";
import ShowCartShow from "./_show_cat_show";

class ShowCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      cart_items: [],
      cart_data: [],
      locations: [],
      marketplace_products_countries: [],
      marketplace_products_regions: [],
      marketplace_products_weights: [],
    };
    this._tableRef = React.createRef();
    this._reviewCartRef = React.createRef();
    this._summaryRef = React.createRef();
    this._ShowCartShowRef = React.createRef();
  }

  reloadData = () => {
    this.fetchCart(this.state.cart_id);
  };

  handleOpen = (cart_id, name) => {
    this.setState({
      open: true,
      name: name,
      cart_items: [],
      cart_data: [],
      cart_id: cart_id,
    });
    this.fetchCart(cart_id);
  };

  fetchCart = (cart_id) => {
    this.setState({ working: true });
    const url = `/carts/${cart_id}.json`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        this.setState({
          cart_items: result.cart_items,
          cart_data: result.cart_data,
          marketplace_products_countries: result.marketplace_products_countries,
          marketplace_products_regions: result.marketplace_products_regions,
          marketplace_products_weights: result.marketplace_products_weights,
          working: false,
        });
      })
      .catch((error) => {
        this.setState({ working: false });
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = (item_id) => {
    let self = this;
    this.setState({ working: true });
    const { cart_id } = this.state;
    $.ajax({
      url: `/carts/${cart_id}/remove_item`,
      type: "PATCH",
      data: { order_item_id: item_id },
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully deleted!", {
          variant: "success",
        });
        self.fetchCart(cart_id);
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  handleUpdateCart = () => {
    let self = this;
    this.setState({ working: true });
    const { cart_id } = this.state;
    $.ajax({
      url: `/carts/${cart_id}`,
      type: "PATCH",
      data: { status: "reviewed" },
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.fetchCart(cart_id);
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  handleClearCart = () => {
    let self = this;
    this.setState({ working: true });
    const { cart_id } = this.state;
    $.ajax({
      url: `/carts/${cart_id}/clear`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully deleted!", {
          variant: "success",
        });
        self.handleClose();
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  callbackClose = () => {
    this.setState({ open: false });
  };

  render() {
    const columns = [
      {
        title: "",
        field: "image",
        sorting: false,
        cellStyle: { width: "10%" },
        render: (row) => (
          <a href={row.url} target="_blank">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60px",
              }}
            >
              <Badge
                badgeContent="oversize"
                invisible={!row.over_size}
                overlap="circular"
                color="error"
              >
                <img
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "contain",
                  }}
                  alt={row.name}
                  src={row.image}
                  title={row.name}
                />
              </Badge>
            </div>
          </a>
        ),
      },
      {
        title: "",
        field: "Category",
        render: (row) => (
          <>
            {row.category && (
              <p className="p-0 m-0">
                <small>{row.category}</small>
              </p>
            )}
            {row.sub_category && (
              <p className="p-0 m-0">
                <small>{row.sub_category}</small>
              </p>
            )}
            {row.sub_category_one && (
              <p className="p-0 m-0">
                <small>{row.sub_category_one}</small>
              </p>
            )}
          </>
        ),
      },
      { title: "Price", field: "price", cellStyle: { width: "5%" } },
      { title: "QTY", field: "quantity", cellStyle: { width: "5%" } },
      { title: "Shipping", field: "shipping_cost", cellStyle: { width: "5%" } },
      {
        title: "Shipped by",
        field: "shipping_option",
        cellStyle: { width: "5%" },
      },
      {
        title: "Docking Fee",
        field: "docking_fee",
        cellStyle: { width: "5%" },
      },
      {
        title: "Insurance",
        field: "item_insurance",
        sorting: false,
        cellStyle: { width: "5%" },
      },
      {
        title: "Ship to HUB",
        field: "shipping_to_hub",
        sorting: false,
        cellStyle: { width: "5%" },
      },
      { title: "Predict Weight", field: "predict_weight" },
      { title: "Predict", field: "predict" },
      { title: "Predict Dimension", field: "predict_dimensions" },
      { title: "Weight", field: "weight" },
      { title: "Dimensions", field: "dimensions" },
      { title: "Size", field: "size" },
      { title: "Adjustment", field: "review_adjustment_cost", sorting: false },
    ];

    const title = (
      <div className="container-fluid">
        <ReviewCart ref={this._reviewCartRef} reloadData={this.fetchCart} />
        <Backdrop open={this.state.working} />
        <Summary ref={this._summaryRef} />
        <ShowCartShow
          ref={this._ShowCartShowRef}
          callback={this.callbackClose}
        />
        <div className="row">
          {this.state.cart_data?.global?.map((item, index) => (
            <div className="col-3">
              <Typography variant="subtitle1" color="textSecondary">
                {item[0]} <b>{item[1]}</b>
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
    const body = (
      <Table
        title={`${this.state.name}'s Cart`}
        options={{
          toolbar: true,
          filtering: false,
          paging: false,
          search: false,
          selection: true,
          debounceInterval: 500,
          actionsColumnIndex: -1,
        }}
        columns={columns}
        tableRef={this._tableRef}
        actions={[
          {
            icon: () => <pureIcons.BiShow />,
            onClick: (event, rowData) => {
              this._ShowCartShowRef.current.handleOpen(
                rowData,
                this.state.marketplace_products_weights,
                this.state.marketplace_products_regions,
                this.state.marketplace_products_countries
              );
            },
            position: "row",
            tooltip: "Show",
          },
          {
            icon: () => <pureIcons.Delete />,
            onClick: (event, rowData) => {
              window.confirm(
                `Are you sure you want to remove ${rowData.name} from the cart?`
              ) && this.handleDelete(rowData.id);
            },
            position: "row",
            tooltip: "Delete",
          },
          {
            tooltip: "Review/Update",
            icon: () => <pureIcons.Edit />,
            onClick: (event, data) =>
              this._reviewCartRef.current.handleOpen(
                data,
                this.state.cart_id,
                this.state.cart_data.has_privilege
              ),
          },
        ]}
        data={this.state.cart_items}
      />
    );

    const actions = (
      <>
        <Button
          variant="contained"
          color="primary"
          hidden={this.state.cart_items.length === 0}
          onClick={() =>
            window.confirm(
              `Are you sure you want to delete all items in this cart?`
            ) && this.handleClearCart()
          }
        >
          Clear Cart
        </Button>
        {this.state.cart_data?.locations?.map((o, i) => (
          <Button
            key={i}
            variant="contained"
            onClick={() =>
              this._summaryRef.current.handleOpen(Object.values(o))
            }
          >
            {Object.keys(o)}
          </Button>
        ))}
        <div style={{ flex: "1 0 0" }} />
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          hidden={!this.state.cart_data?.pending}
          onClick={() =>
            window.confirm(`Are you sure you want to commit cart changes?`) &&
            this.handleUpdateCart()
          }
        >
          Commit Review
        </Button>
      </>
    );

    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        title={title}
        maxHeight="90%"
        maxWidth="xl"
        actions={actions}
      >
        {this.state.working ? <TableSkeleton /> : body}
      </Modal>
    );
  }
}

export default withSnackbar(ShowCart);

class ReviewCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      working: false,
      products: [],
    };
  }

  convertDimensions = (products) => {
    return products.map((product) => {
      const { dimensions, review_dimension, ...rest } = product;

      let dimensionLength = null;
      let dimensionWidth = null;
      let dimensionHeight = null;
      let dimensionUnit = null;

      let reviewDimensionLength = null;
      let reviewDimensionWidth = null;
      let reviewDimensionHeight = null;
      let reviewDimensionUnit = null;

      // Function to process a dimension string and extract length, width, height, and unit
      const extractDimensions = (dimensionStr) => {
        let length = null;
        let width = null;
        let height = null;
        let unit = null;

        if (dimensionStr) {
          const dimensionParts = dimensionStr.split(/\s+/);
          const numericParts = dimensionParts.filter((part) =>
            /^\d+(\.\d+)?$/.test(part)
          );

          if (numericParts.length >= 3) {
            [length, width, height] = numericParts.slice(0, 3).map(Number);

            const unitIndex = dimensionParts.findIndex(
              (part) =>
                !(/^\d+(\.\d+)?$/.test(part) || part.toLowerCase() === "x")
            );
            if (unitIndex !== -1) {
              unit =
                dimensionParts
                  .slice(unitIndex)
                  .join(" ")
                  .replace(/[()]/g, "")
                  .trim() || null;
            }
          }
        }

        return { length, width, height, unit };
      };

      // Extract dimensions from both `dimensions` and `review_dimension`
      const dimensionsData = extractDimensions(dimensions);
      const reviewDimensionsData = extractDimensions(review_dimension);

      return {
        ...rest,
        dimensionLength: dimensionsData.length,
        dimensionWidth: dimensionsData.width,
        dimensionHeight: dimensionsData.height,
        dimensionUnit: dimensionsData.unit,
        reviewDimensionLength: reviewDimensionsData.length,
        reviewDimensionWidth: reviewDimensionsData.width,
        reviewDimensionHeight: reviewDimensionsData.height,
        reviewDimensionUnit: reviewDimensionsData.unit,
      };
    });
  };
  handleOpen = (products, cart_id, has_privilege) => {
    this.setState({
      open: true,
      products: this.convertDimensions(products),
      cart_id: cart_id,
      errors: {},
      has_privilege: has_privilege,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInputChange = (e, product) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      products: prevState.products.map((obj) =>
        obj.id === product.id ? Object.assign(obj, { [name]: value }) : obj
      ),
    }));
    this.validate({ [name]: value });
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    this.setState({ working: true });
    const { products, cart_id } = this.state;
    const updatedProducts = products.map((product) => {
      const {
        dimensionLength,
        dimensionWidth,
        dimensionHeight,
        dimensionUnit,
        reviewDimensionLength,
        reviewDimensionWidth,
        reviewDimensionHeight,
        reviewDimensionUnit,
        ...rest
      } = product;

      // Rejoin the dimension values for `dimensions`
      const dimensionsStr = [dimensionLength, dimensionWidth, dimensionHeight]
        .filter((val) => val !== null && val !== undefined)
        .join(" x ");

      // const dimensions =
      //   dimensionsStr + (dimensionUnit ? ` ${dimensionUnit}` : "");

      // Rejoin the dimension values for `review_dimension`
      const reviewDimensionsStr = [
        reviewDimensionLength,
        reviewDimensionWidth,
        reviewDimensionHeight,
      ]
        .filter((val) => val !== null && val !== undefined)
        .join(" x ");

      // const review_dimension =
      //   reviewDimensionsStr +
      //   (reviewDimensionUnit ? ` ${reviewDimensionUnit}` : "");

      return {
        ...rest,
        dimensions: dimensionsStr,
        dimensions_unit: dimensionUnit,
        review_dimension: reviewDimensionsStr,
        review_dimension_unit: reviewDimensionUnit,
      };
    });

    const data = { items: updatedProducts };
    $.ajax({
      url: `/carts/${cart_id}/review`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false, open: false });
        self.props.reloadData(cart_id);
      },
      error: function (response) {
        self.setState({
          working: false,
          errors: { serverErrors: response.responseJSON.error },
        });
      },
    });
  };

  extractUnits = (input) => {
    input = input?.trim().toLowerCase();
    const units = input?.replace(/[\d.]+/g, "").trim();
    const normalizedUnits = units?.replace(/\s+/g, " ");
    return normalizedUnits;
  };

  render() {
    const body = (
      <div className="container-fluid">
        <Backdrop open={this.state.working} />
        {this.state.errors.serverErrors && (
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        )}
        {this.state.products.map((product, i) => (
          <div className="row">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-2 mt-2">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <a href={product.url} target="_blank">
                      <Badge
                        badgeContent="oversize"
                        invisible={!product.over_size}
                        overlap="circular"
                        color="error"
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "contain",
                          }}
                          alt={product.name}
                          src={product.image}
                          title={product.name}
                        />
                      </Badge>
                    </a>
                  </div>
                  <div className="m-3">
                    {product.basic.map((c, i) =>
                      c.value ? (
                        <p key={i} className="p-0 ps-1 m-0">
                          <b>{c.value}</b>
                        </p>
                      ) : null
                    )}
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="card-body">
                    <p>{product.name}</p>
                    <div className="row">
                      {product.customizations.map((c, i) =>
                        c.value ? (
                          <div key={i} className="col-6">
                            {c.name}: <b>{c.value}</b>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="card-body">
                    {this.state.has_privilege &&
                      (!product.dimensions ||
                        product.dimensions ||
                        product.dimensions_by_reviewer) && (
                        <>
                          <div className="row pt-1">
                            <div className="col">
                              {/* <TextField
                              fullWidth
                              name="dimensions"
                              value={product.dimensions}
                              label="Dimensions"
                              onChange={(e) =>
                                this.handleInputChange(e, product)
                              }
                            /> */}
                              <Grid container spacing={2}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  spacing={2}
                                  key={product.id}
                                >
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Dimension Length"
                                      name="dimensionLength"
                                      value={product.dimensionLength || ""}
                                      onChange={(e) =>
                                        this.handleInputChange(e, product)
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Dimension Width"
                                      name="dimensionWidth"
                                      value={product.dimensionWidth || ""}
                                      onChange={(e) =>
                                        this.handleInputChange(e, product)
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                          <div className="row pt-1">
                            <div className="col">
                              <Grid container spacing={2}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  spacing={2}
                                  key={product.id}
                                >
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Dimension Height"
                                      name="dimensionHeight"
                                      value={product.dimensionHeight || ""}
                                      onChange={(e) =>
                                        this.handleInputChange(e, product)
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      <InputLabel id="unit-label">
                                        Dimension Unit
                                      </InputLabel>
                                      <Select
                                        labelId="unit-label"
                                        name="dimensionUnit"
                                        value={product.dimensionUnit || ""}
                                        onChange={(e) =>
                                          this.handleInputChange(e, product)
                                        }
                                      >
                                        <MenuItem value="inches">
                                          inches
                                        </MenuItem>
                                        {/* Add other units as needed */}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </>
                      )}
                    {(product.dimensions || !product.dimensions) && (
                      <>
                        <div className="row pt-1">
                          <div className="col">
                            <Grid container spacing={2}>
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={product.id}
                              >
                                <Grid item xs={6}>
                                  <TextField
                                    label="Review Dimension Length"
                                    name="reviewDimensionLength"
                                    value={product.reviewDimensionLength || ""}
                                    onChange={(e) =>
                                      this.handleInputChange(e, product)
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    label="Review Dimension Width"
                                    name="reviewDimensionWidth"
                                    value={product.reviewDimensionWidth || ""}
                                    onChange={(e) =>
                                      this.handleInputChange(e, product)
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                        <div className="row pt-1">
                          <div className="col">
                            <Grid container spacing={2}>
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={product.id}
                              >
                                <Grid item xs={6}>
                                  <TextField
                                    label="Review Dimension Height"
                                    name="reviewDimensionHeight"
                                    value={product.reviewDimensionHeight || ""}
                                    onChange={(e) =>
                                      this.handleInputChange(e, product)
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id="unit-label">
                                      Review Dimension Unit
                                    </InputLabel>
                                    <Select
                                      labelId="unit-label"
                                      name="reviewDimensionUnit"
                                      value={product.reviewDimensionUnit || ""}
                                      onChange={(e) =>
                                        this.handleInputChange(e, product)
                                      }
                                    >
                                      <MenuItem value="inches">inches</MenuItem>
                                      {/* Add other units as needed */}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.has_privilege &&
                      !product.standard_weight &&
                      (!product.weight ||
                        product.weight ||
                        product.weight_by_reviewer) && (
                        <div className="row pt-1">
                          <div className="col-8">
                            <TextField
                              fullWidth
                              name="weight"
                              value={product.weight}
                              label="Weight"
                              helperText="If weight value doesn't have unit, please select unit"
                              onChange={(e) =>
                                this.handleInputChange(e, product)
                              }
                            />
                          </div>
                          <div className="col">
                            <TextField
                              name="weight_unit"
                              select
                              fullWidth
                              label="Weight unit"
                              value={this.state.weight_unit}
                              // value={
                              //   product.weight_unit ||
                              //   this.extractUnits(product.weight)
                              // }
                              onChange={(e) =>
                                this.handleInputChange(e, product)
                              }
                              SelectProps={{ native: true }}
                            >
                              {["", "lb"].map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </TextField>
                          </div>
                        </div>
                      )}
                    {product.standard_weight && (
                      <div className="row pt-1">
                        <div className="col-8">
                          <TextField
                            fullWidth
                            name="category_weight"
                            value={product.category_weight}
                            label="Category weight"
                            helperText="If weight value doesn't have unit, please select unit"
                            onChange={(e) => this.handleInputChange(e, product)}
                          />
                        </div>
                        <div className="col">
                          <TextField
                            name="category_weight_unit"
                            select
                            fullWidth
                            label="Weight unit"
                            value={this.state.category_weight_unit}
                            // value={
                            //   product.category_weight_unit ||
                            //   this.extractUnits(product.category_weight)
                            // }
                            onChange={(e) => this.handleInputChange(e, product)}
                            SelectProps={{ native: true }}
                          >
                            {["", "lb"].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    )}
                    <div className="row pt-1">
                      <div className="col-8">
                        <TextField
                          fullWidth
                          name="review_weight"
                          value={product.review_weight}
                          label="Review weight"
                          helperText="If weight value doesn't have unit, please select unit"
                          onChange={(e) => this.handleInputChange(e, product)}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          name="review_weight_unit"
                          select
                          fullWidth
                          label="Weight unit"
                          value={this.state.review_weight_unit}
                          // value={
                          //   product.review_weight_unit ||
                          //   this.extractUnits(product.review_weight)
                          // }
                          onChange={(e) => this.handleInputChange(e, product)}
                          SelectProps={{ native: true }}
                        >
                          {["", "lb"].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    {this.state.has_privilege && (
                      <div className="row pt-1">
                        <div className="col-8">
                          <TextField
                            fullWidth
                            name="weight_liquid"
                            value={product.weight_liquid}
                            label="Weight liquid"
                            helperText="If weight value doesn't have unit, please select unit"
                            onChange={(e) => this.handleInputChange(e, product)}
                          />
                        </div>
                        <div className="col">
                          <TextField
                            name="weight_liquid_unit"
                            select
                            fullWidth
                            label="Weight unit"
                            value={this.state.weight_liquid_unit}
                            // value={
                            //   product.weight_liquid_unit ||
                            //   this.extractUnits(product.weight_liquid)
                            // }
                            onChange={(e) => this.handleInputChange(e, product)}
                            SelectProps={{ native: true }}
                          >
                            {["", "fl oz", "ml"].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    )}
                    {product.weight_liquid &&
                      !product.weight &&
                      !product.weight_item &&
                      !product.product_dimensions &&
                      !product.category_weight && (
                        <div className="row pt-1">
                          <div className="col-8">
                            <TextField
                              fullWidth
                              name="review_weight_liquid"
                              value={product.review_weight_liquid}
                              label="Review weight liquid"
                              helperText="If weight value doesn't have unit, please select unit"
                              onChange={(e) =>
                                this.handleInputChange(e, product)
                              }
                            />
                          </div>
                          <div className="col">
                            <TextField
                              name="review_weight_liquid_unit"
                              select
                              fullWidth
                              label="Weight unit"
                              value={this.state.review_weight_liquid_unit}
                              // value={
                              //   product.review_weight_liquid_unit ||
                              //   this.extractUnits(product.review_weight_liquid)
                              // }
                              onChange={(e) =>
                                this.handleInputChange(e, product)
                              }
                              SelectProps={{ native: true }}
                            >
                              {["", "fl oz", "ml"].map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </TextField>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="card-body">
                    <div className="row pt-1">
                      <div className="col">
                        <TextField
                          fullWidth
                          name="quantity"
                          value={product.quantity}
                          label="Quantity"
                          onChange={(e) => this.handleInputChange(e, product)}
                        />
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col">
                        <TextField
                          fullWidth
                          name="min_quantity"
                          value={product.min_quantity}
                          label="Min quantity"
                          onChange={(e) => this.handleInputChange(e, product)}
                        />
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col">
                        <TextField
                          fullWidth
                          name="max_quantity"
                          value={product.max_quantity}
                          label="Max quantity"
                          onChange={(e) => this.handleInputChange(e, product)}
                        />
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col">
                        <TextField
                          fullWidth
                          multiple
                          name="note"
                          value={product.note}
                          label="Note"
                          onChange={(e) => this.handleInputChange(e, product)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    const actions = (
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        onClick={this.handleSubmit}
      >
        Submit
      </Button>
    );

    return (
      <Modal
        disableBackdropClick
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight="40vh"
        maxHeight="85vh"
        maxWidth="md"
        actions={actions}
      >
        {body}
      </Modal>
    );
  }
}

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = (data) => {
    this.setState({ open: true, data: data });
  };

  render() {
    const body = (
      <Paper>
        <table className="table table-striped">
          <tbody>
            {this.state.data[0]?.map((row, i) => (
              <tr key={i}>
                <th scope="row">{row[0]}</th>
                <td>{row[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
    );
    return (
      <Modal
        isOpen={this.state.open}
        title="Orders Summary"
        handleClose={this.handleClose}
        minHeight="40vh"
        maxHeight="75%"
        maxWidth="sm"
      >
        {body}
      </Modal>
    );
  }
}
