import React from 'react'
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles'
import {Button, Card, TextField} from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import Backdrop from '../shared/backdrop'
import {withSnackbar} from 'notistack'

class CreateCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: '',
      values: this.initialValues()
    }
  }

  initialValues = () => {
    return {
      user_id: '',
      country: '',
      last4: '',
      status: '',
      phone_number: ''
    }
  }

  handleOpen = () => {
    this.setState({
      open: true,
      values: this.initialValues(),
    })
  }

  handleClose = () => {
    this.setState({open: false})

  }

  handleSubmit = event => {
    let self = this
    let type = this.state.type
    event.preventDefault()
    if (!this.validate()) return
    let values = this.state.values

    let payload = {
      last4: values.last4,
      country: values.country,
      user_id: values.user_id,
      status: values.status,
      phone_number: values.phone_number
    }

    $.ajax({
      url: `/prepaid_card_accesses`,
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('Card Successfully Added!', {
          variant: 'success'
        })
        self.handleClose()
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
        self.setState({working: false});
      }
    })


  }

  handleInputChange = e => {
    const {name, value} = e.target
    if (!value || value.match(/^[A-Z]{0,2}$/)) {
      this.setState({values: {...this.state.values, [name]: value}})
    }
    this.validate({[name]: value})
  }
  handleInputDigitsChange = e => {
    const {name, value} = e.target
    if (!value || value.match(/^\d{0,4}$/)) {
      this.setState({values: {...this.state.values, [name]: value}})
    }
    this.validate({[name]: value})
  }
  handleSelectChange = e => {
    const {name, value} = e.target
    this.setState({values: {...this.state.values, [name]: value}})
    this.validate({[name]: value})
  }
  handleInputNumberChange = e => {
    const {name, value} = e.target
    if (!value || value.match(/^\d{1,}$/)) {
      this.setState({values: {...this.state.values, [name]: value}})
    }
    this.validate({[name]: value})
  }

  validate = (
    fieldValues = this.state.values
  ) => {
    let temp = {...this.state.errors}
    temp.serverErrors = ''
    if ('last4' in fieldValues) {
      temp.last4 = fieldValues.last4 ? '' : 'Required'
    }
    if ('country' in fieldValues) {
      temp.country = fieldValues.country ? '' : 'Required'
    }
    if ('user_id' in fieldValues) {
      temp.user_id = fieldValues.user_id ? '' : 'Required'
    }
    if ('phone_number' in fieldValues) {
      temp.phone_number = fieldValues.phone_number ? '' : 'Required'
    }
    if ('status' in fieldValues) {
      temp.status = fieldValues.status
        ? ''
        : 'Required'
    }

    this.setState({errors: {...temp}})

    fieldValues = this.state.values


    return Object.values(temp).every(x => x === '')
  }

  render() {
    const {classes} = this.props
    const body = (
      <>
        {this.state.errors.serverErrors && (
          <div className='alert alert-danger p-1 text-center' role='alert'>
            {this.state.errors.serverErrors}
          </div>
        )}
        <Card className={classes.root} style={{minHeight: '35vh'}}>
          <Backdrop open={this.state.working}/>
          <div className='container'>
            <TextField
              name='last4'
              fullWidth
              margin='normal'
              label='Last 4 Digits'
              intialValue={
                this.state.values.last4
              }
              onChange={this.handleInputDigitsChange}
              {...(this.state.errors.last4 && {
                error: true,
                helperText: this.state.errors.last4
              })}
            ></TextField>

            <TextField
              name='country'
              fullWidth
              margin='normal'
              label='Country'
              intialValue={
                this.state.values.country
              }
              onChange={this.handleInputChange}
              {...(this.state.errors.country && {
                error: true,
                helperText: this.state.errors.country
              })}
            ></TextField>
            <TextField
              name='user_id'
              fullWidth
              margin='normal'
              label='User Id'
              intialValue={
                this.state.values.user_id
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.user_id && {
                error: true,
                helperText: this.state.errors.user_id
              })}
            ></TextField>
            <TextField
              name='phone_number'
              fullWidth
              margin='normal'
              label='Phone Number'
              intialValue={
                this.state.values.phone_number
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.phone_number && {
                error: true,
                helperText: this.state.errors.phone_number
              })}
            ></TextField>
            <TextField
              name="status"
              select
              fullWidth
              label="Type"
              {...(this.state.errors.status && {error: true, helperText: this.state.errors.status})}
              intialValue={this.state.values.status}
              onChange={this.handleSelectChange}
              SelectProps={{native: true}}
            >
              {['', 'blacklist', 'whitelist', 'rejected'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </Card>
      </>
    )

    const actions = (
      <>
        <Button
          type='button'
          variant='contained'
          onClick={() =>
            this.setState({values: this.initialValues(), errors: {}})
          }
        >
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          {this.state.working ? 'Working...' : 'Submit'}
        </Button>
      </>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title='Add Credit'
        handleClose={this.handleClose}
        // minHeight='50vh'
        maxHeight='50%'
        maxWidth='sm'
        actions={actions}
      >
        {body}
      </Modal>
    )
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
})

export default withSnackbar(withStyles(useStyles)(CreateCard))
