import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    withStyles
} from '@material-ui/core';


const ShowDetails = ({ selectedId }) => {

    const [data, setData] = useState<any>({});
    const StyledTableCell = withStyles(() => ({
        root: {
            border: '1px solid rgba(224, 224, 224, 1)', // Add border style
        },
    }))(TableCell);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/coupons/${selectedId}.json?locale=en`);
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [selectedId]);

    const getVendors = (): string => {
        if (Array.isArray(data.vendors_by_region)) {
            return data.vendors_by_region.map((obj: any) => `${obj.region} : ${obj.vendors}`).join("\n");
        } else {
            return data.vendors_by_region;
        }
    }


    return (
        <TableContainer component={Paper}>
            <Table aria-label="coupon details">
                <TableBody>
                    <TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Coupon Name</StyledTableCell>
                        <StyledTableCell>{data.coupon_number || ''}</StyledTableCell>
                    </TableRow>
                    <TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Start Date</StyledTableCell>
                        <StyledTableCell>{data.start_date || ''}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>End Date</StyledTableCell>
                        <StyledTableCell>{data.end_date || ''}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Discount Type</StyledTableCell>
                        <StyledTableCell>{data.discount_type == null ? '' : data.discount_value}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Discount Value</StyledTableCell>
                        <StyledTableCell>{data.discount_value == null ? '' : data.discount_value}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Coupon Type</StyledTableCell>
                        <StyledTableCell>{data.coupon_type == null ? '' : data.coupon_type}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Minimum total needed</StyledTableCell>
                        <StyledTableCell>{data.min_total == null ? '' : data.min_total}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Minimum Total Type</StyledTableCell>
                        <StyledTableCell>{data.min_total_type == null ? '' : data.min_total_type}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>No. of times used</StyledTableCell>
                        <StyledTableCell>{data.no_of_times_used == null ? '' : data.no_of_times_used}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Coupon is Active?(Start date with current time of server)</StyledTableCell>
                        <StyledTableCell>{String(data.coupon_is_active)}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Coupon is Expired? (End date with current time of server)</StyledTableCell>
                        <StyledTableCell>{String(data.coupon_is_expired)}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Vendors</StyledTableCell>
                        <StyledTableCell>{getVendors()}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Number of Uses? (For limited uses)</StyledTableCell>
                        <StyledTableCell>{data.used == null ? '' : data.used}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Coupon Used (For Limited Uses)</StyledTableCell>
                        <StyledTableCell>{data.number_of_use == null ? '' : data.number_of_use}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Payment Gateway</StyledTableCell>
                        <StyledTableCell>{data.payment_gateway || ''}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>User Type Option</StyledTableCell>
                        <StyledTableCell>{data.user_type || ''}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Activity Option</StyledTableCell>
                        <StyledTableCell>{data.activity || ''}</StyledTableCell>
                    </TableRow><TableRow >
                        <StyledTableCell style={{ fontWeight: "bold" }}>Max Discount Amount</StyledTableCell>
                        <StyledTableCell>{data.max_discount_amount || ''}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ShowDetails;
