import React from "react";
import Modal from "../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import Backdrop from "../shared/backdrop";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  Badge,
  CardMedia,
  CardContent,
  Typography,
  Link,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import NoteCard from "../notes/_note_card";
import StageTabs from "./_item_stages_tabs";
import ShowOrder from "../orders/_show";
import CopyTextComponent from "../shared/copy_clipboard";
class ShowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      working: false,
      item: {},
    };
    this._ShowOrderRef = React.createRef();
  }

  handleOpen = (item_id) => {
    this.setState({ open: true, working: true });
    this.getItem(item_id);
  };

  reloadData = () => {
    this.getItem(this.state.item.order_item_id);
    this.props.reloadData();
  };

  getItem = (item_id) => {
    const url = `/order_items/${item_id}.json?`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        this.setState({ item: result, working: false });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error, { variant: "error" });
        this.setState({ working: false });
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const customization = this.state.item.customizations?.map((c) => {
      if (c.value !== null && c.value !== "")
        return (
          <Typography variant="subtitle1" color="textSecondary" key={c.name}>
            {c.name}: <b>{c.value}</b>
          </Typography>
        );
    });
    const body = (
      <>
        <Card className={classes.root}>
          <Backdrop open={this.state.working} />
          <ShowOrder ref={this._ShowOrderRef} />
          <CardMedia className={classes.cover}>
            <Badge
              badgeContent="DG"
              invisible={!this.state.item.dg}
              overlap="circular"
              color="error"
            >
              <img
                src={this.state.item.image}
                alt={this.state.item.name}
                style={{
                  height: "250px",
                  width: "200px",
                  objectFit: "contain",
                }}
              />
            </Badge>
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                <Link href={this.state.item.url} target="_blank">
                  {this.state.item.name}
                </Link>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.item.prohibited && (
                    <Alert variant="filled" severity="error">
                      This item could be Prohibited — please check it out!
                    </Alert>
                  )}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Price: <b>${this.state.item.price}</b>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Quantity: <b>{this.state.item.qty}</b>
                      </Typography>
                      {this.state.item.shipping_to_hub !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Local Shipping:{" "}
                          <b>${this.state.item.shipping_to_hub}</b>
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color="textSecondary">
                        Customs Fees: <b>${this.state.item.customs_fees}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {this.state.item.adjustment !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Adjustment fee: <b>${this.state.item.adjustment}</b>
                        </Typography>
                      )}
                      {this.state.item.insurance !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Insurance: <b>${this.state.item.insurance}</b>
                        </Typography>
                      )}
                      {this.state.item.dangerous_fee !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          DG Fees: <b>${this.state.item.dangerous_fee}</b>
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color="textSecondary">
                        Shipping Fees:{" "}
                        <b>${this.state.item.shipped_to_final_destination}</b>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Predict: <b>{this.state.item.predict}</b>
                      </Typography>
                      {this.state.item.vendor_shipping && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Vendor shipping:{" "}
                          <b>${this.state.item.vendor_shipping}</b>
                        </Typography>
                      )}
                      {this.state.item.profit !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Shipping Profit: <b>{this.state.item.profit}</b>
                        </Typography>
                      )}
                      {this.state.item.category_weight && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Category weight:{" "}
                          <b>{this.state.item.category_weight}</b>
                        </Typography>
                      )}
                      {this.state.item.review_weight && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review weight: <b>{this.state.item.review_weight}</b>
                        </Typography>
                      )}
                      {this.state.item.review_dimensions && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review dimensions:{" "}
                          <b>{this.state.item.review_dimensions}</b>
                        </Typography>
                      )}
                      {this.state.item.review_weight_liquid && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review weight liquid:{" "}
                          <b>{this.state.item.review_weight_liquid}</b>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" color="textSecondary">
                    Item ID: <b>{this.state.item.order_item_id}</b>
                  </Typography>
                  {customization}
                </Grid>
                <Grid item xs={6}>
                  <div>
                    {this.state.item.notes &&
                      this.state.item.notes.length > 0 && (
                        <NoteCard notes={this.state.item.notes} />
                      )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Card>
        <div className={classes.root}>
          <StageTabs item={this.state.item} reloadData={this.reloadData} />
        </div>
      </>
    );
    const orderTitle = (
      <>
        <Button
          style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
          onClick={() =>
            this._ShowOrderRef.current.handleOpen(
              this.state.item.order_id,
              this.state.item.order_number
            )
          }
        >
          {this.state.item.order_number}
        </Button>
        {`(${this.state.item.brand}) `}
        <IconButton
          onClick={() =>
            navigator.clipboard.writeText(this.state.item.order_number)
          }
          aria-label="copy"
        >
          <CopyTextComponent textValue={this.state.item.order_number} />
        </IconButton>
      </>
    );
    return (
      <Modal
        disableBackdropClick={false}
        isOpen={this.state.open}
        title={orderTitle}
        handleClose={this.handleClose}
        minHeight="85%"
        maxWidth="xl"
        maxHeight="85%"
        actions={<></>}
      >
        {body}
      </Modal>
    );
  }
}
const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 10,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
    height: 250,
    objectFit: "contain",
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});
export default withSnackbar(withStyles(useStyles)(ShowItem));
